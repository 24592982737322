<template>

<div class="cart-view">
	<div class="cart">
		<div class="loading" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
		<h1>Your Cart</h1>
		<h2 class="empty-cart" v-if="items.length == 0">Your cart is empty</h2>
		<template v-for="(item, index) in items" v-else>
			<div class="item-divider"></div>
			<router-link v-if="item.product_type_code != 'MEMBERSHIPS'" :to="'/product/' + item.link_name">
				<img :src="item.image_src" :alt="item.description" style="max-width: 100%; max-height: 10em;">
			</router-link>
			<img v-else :src="item.image_src" :alt="item.description" style="max-width: 100%; max-height: 10em;">
			<div class="description">
				<router-link :to="'/product/' + item.link_name" v-if="item.product_type_code != 'MEMBERSHIPS'">
					<h2>{{ item.description }}</h2>
				</router-link>
				<h2 v-else>{{ item.description }}</h2>
				<div class="columns columns-2 cart-meta">
					<span>PID: {{ item.product_id }}</span>
					<span>UPC: {{ item.upc }}</span>
				</div>
				<div class="columns columns-2 cart-item-controls">
					<button class="button primary" @click="openWishListModal(item)" v-if="item.product_type_code != 'MEMBERSHIPS'">Save for Later</button>
					<button class="button primary" @click="updateQuantity(item, 1)">Delete</button>
				</div>
				<div v-if="itemAlerts[item.product_id]" class="error">{{ itemAlerts[item.product_id] }}</div>
			</div>
			<div class="price">${{ item.sale_price }}</div>

			<div class="quantity" v-if="!loading">
				<label :for="'quantity_' + item.product_id">Qty.</label>
				<span class="over-ten" v-if="item.show_ten_plus_input">
					<input :id="'quantity_' + item.product_id" class="over-ten-quantity" v-model="item.quantity" @change="updateQuantity(item)" type="number"><br>
					<sup style="text-decoration: underline; cursor: pointer;" >Update Quantity</sup>
				</span>
				<span v-else>
					<select v-if="item.product_type_code != 'MEMBERSHIPS' && item.product_type_code != 'CLASSES'" class="item-quantity" :id="'quantity_' + item.product_id" v-model="item.quantity" @change="updateQuantity(item)" :key="'quantity_' + item.available">
						<option :value="value" v-for="(value, index) in quantityList(item)">{{ value }}</option>
						<option value="10plus" v-if="parseInt(item.available) >= 10">10+</option>
					</select>
					<span v-else>
						{{ item.quantity }}
					</span>
				</span>
			</div>

			<div class="extended-price">${{ (item.sale_price * item.quantity).toFixed(2) }}</div>
		</template>
		<div class="divider"></div>
		<div class="label subtotal">Subtotal</div>
		<div class="amount subtotal">${{ totals.subtotal }}</div>
		<div></div>
		<div></div>
		<a href="/checkout" class="button action checkout">Checkout</a>
	</div>

    <div v-if="getClient.is_demo" class="in-demo-mode">This is a demo website!  Prices are not accurate and checkout is disabled.</div>

	<wish-list-modal @cancel="wishListModalOpen = false" :productId="wishListItem.product_id" @itemAdded="updateQuantity(wishListItem, 1)" v-if="wishListModalOpen"></wish-list-modal>
</div>

</template>

<script>
export default {
	name: 'cart',
	data () {
		return {
			loading: true,
			available: 5,
			items: [],
			itemAlerts: [],
			totals: {},
			wishListModalOpen: false,
			wishListItem: {}
		}
	},
	created() {
		this.fetchData()
	},
	methods: {
		fetchData() {
			this.apiCall({ destination: 'get_shopping_cart', data: { include_images: true } }).then(obj => {
				this.items = obj.items
				// set item's show_ten_plus element based on quantity
				for (var key in this.items) {
					this.items[key]['show_ten_plus'] = parseInt(this.items[key]['available']) >= 10
					this.items[key]['show_ten_plus_input'] = parseInt(this.items[key]['quantity']) >= 10
				}
				this.itemAlerts = obj.item_alerts
				this.totals = obj.totals
				this.loading = false
			}).catch(err => {
				console.log(err)
				this.loading = false
			})
		},
		quantityList(item) {
			var list = []
			var limit = 0
			if (item.per_checkout) {
				limit = Math.min(parseInt(item.per_checkout), 9)
			} else {
				if (item.is_inventory == 1) {
					limit = Math.min(parseInt(item.available), 9)
				} else {
					limit = 9
				}
			}
			for (var i = 1; i < limit + 1; i++) {
				if (i < 10) {
					list.push(i)
				}
			}
			return list
		},
		updateQuantity(item, remove) {
			// handle 10plus option in item's quantity select
			if (item.quantity == '10plus') {
				item.quantity = 10
				item.show_ten_plus_input = true
			}
			let newQuantity = item.quantity
			if (item.is_inventory == 1) {
				newQuantity = Math.min(item.quantity, item.available)
			}
			item.quantity = newQuantity
			this.wishListItem = null
			this.wishListModalOpen = false
			var quantity = (remove) ? 0 : item.quantity
			this.loading = true
			this.apiCall({ destination: 'save_quantity', data: { product_id: item.product_id, shopping_cart_item_id: item.shopping_cart_item_id, quantity: quantity, include_images: true }}).then(obj => {
				this.items = obj.items
				for (var key in this.items) {
					var limit = 0
					if (this.items[key].per_checkout) {
						limit = parseInt(this.items[key].per_checkout)
					} else {
						limit = parseInt(this.items[key].available)
					}
					this.items[key].available = limit
					this.items[key]['show_ten_plus'] = this.items[key].quantity >= 10
					this.items[key]['show_ten_plus_input'] = parseInt(this.items[key]['quantity']) >= 10
				}
				this.totals = obj.totals
				this.loading = false
			}).catch(err => {
				console.log(err)
				this.loading = false
			})
		},
		openWishListModal(item) {
			this.wishListItem = item
			this.wishListModalOpen = true
		}
	}
}
</script>

<style lang="scss" scoped>
.item-divider {
  grid-column: span 5;
  border-bottom: 1px solid #909090;
}
.cart-view {
	.cart {
		.label {
			grid-column: 3;
		}
		.price { font-size: 1.2em; }
		.amount {
			grid-column: 5;
		}
		.quantity {
			.item-quantity {
				font-size: 1.1em;
				margin-left: 0.5em;
				width: 3rem;
			}
			.over-ten {
				display: inline-block;
    			vertical-align: middle;
				margin-left: 1rem;
				input {
					font-size: 1em;
	    			max-width: 2.5rem;
	    			border: 1px solid #CCC;
	    			padding: 11px;
				}
			}
		}
		.error {
			margin: 0.5em 0;
			color: red;
		}
	}
@media (max-width: 1137px) {
	.cart {
		grid-template-columns: 11em 1fr 0.25fr;
		h1, .item-divider, .divider {
			grid-column: span 3;
		}
		.description, .checkout {
			grid-column: 2 / 4;
		}

		.quantity {
			justify-self: start;
		}
		img { width: 100%; }
		.label {
			grid-column: 2;
		}
		.amount {
			grid-column: 3;
		}
	}
}
@media (max-width: 768px) {
	padding: 1rem 3rem;
	.cart {
		grid-template-columns: 1fr 1fr;
		h1, .item-divider, .checkout, .divider, .description {
			grid-column: span 2;
			.cart-meta {
				display: grid;
				grid-template-columns: 1fr 1fr;
				font-size: 0.75rem;
			}
			.cart-item-controls {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 1rem;
			}
		}
		.price {
			font-size: 1rem;
		}
		.quantity {
			justify-self: end;
		}
		.extended-price {
			grid-column: 2;
		}
		.label {
			grid-column: 1;
		}
		.amount {
			grid-column: 2;
		}
	}
}
}

</style>
